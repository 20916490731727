<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCTION PROCESS DATA DETAILS OF PRODUCTION PROCESS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Production Process Data Details of Production Process
        <br />
        <h4>The following is a list of transaction data lists for the Production Process Details of the Production Process</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize2"
                    @change="handlePageSizeChange2($event)"
                  >
                    <option
                      v-for="size2 in pageSizes2"
                      :key="size2"
                      :value="size2"
                    >
                      {{ size2 }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Batch Code"
                    class="form-control"
                    v-model="namesearch2"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchData()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
     
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <h3 class="box-title">Data Stok Barang</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Batch Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdatastok, index2) in list_paged2"
                      :key="classdatastok.id"
                    >
                      <td>{{ index2 + 1 + (page - 1) * pageSize2 }}</td>
                      <!-- <td>{{ classdatastok.id }}</td> -->
                      <td>{{ classdatastok.nomor_produksi }}</td>
                      <td>{{ classdatastok.qty }}</td>
                      <td>{{ classdatastok.notes }}</td>
                      <td>
                        <router-link
                          :to=" '/edit-prosesproduksi-detail-fromproduksi/' + classdatastok.id "
                        >
                          <button
                            title="Edit Production Process Details From Production"
                            class="btn btn-success"
                          >
                            <i class="fa fa-edit"></i> Production Process Details From Production
                          </button> </router-link
                        >&nbsp;
                        <button
                          title="Delete Detailed Production Process from Production Process"
                          @click="deleteData(classdatastok.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange2"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "readpro",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch2: "",
      list_paged2: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize2: 10,
      pageSizes2: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchdata();
  },
  methods: {
    handlePageChange2(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchdata();
    },

    handlePageSizeChange2(event) {
      this.pageSize2 = event.target.value;
      this.page = 1;
      this.fetchdata();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },

    async fetchdata() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "proses_produksi_d_dari_proses_produksi/getalldataproses_produksi_d_dari_proses_produksi?length=" + this.pageSize2 + "&page=" + this.page + "&nomor_produksi=" + this.$route.params.id;
      console.log(urlAPIget)
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged2 = resp.data.data;
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
       /*  .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Peringatan",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        }); */
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    searchData() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch2 == "") {
        window.location.reload();
        this.loading = true;
        this.fetchdata();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/outgoing_h/getalldataoutgoing_h?cari=" + this.namesearch+"&length="+this.pageSize;
        const urlAPIget =
          this.$apiurl +
          "proses_produksi_d_dari_proses_produksi/getalldataproses_produksi_d_dari_proses_produksi?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize +
          "&nomor_produksi=" +
          this.$route.params.id;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged2 = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "proses_produksi_d_dari_proses_produksi/hapusproses_produksi_d_dari_proses_produksi/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              }).then(function(isConfirm) {
                if (isConfirm) {
                  window.location.href = "/";
                }
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
